import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Moment from 'react-moment';

import styled from 'react-emotion';

import { parseAsText } from 'utils/parser';
import media from 'utils/mediaqueries';

import PageHero from 'components/PageHero';
import Seo from 'components/Seo';
import Container from 'components/Container';
import Section from 'components/Section';
import Headline from 'components/Headline';
import TextDefault from 'components/Text';

const SectionJobs = styled(Section)({
  background: 'white',
});

const JobsHeader = styled('header')({
  textAlign: 'left',

  [media('md')]: {
    textAlign: 'center',
  },
});

const JobsDate = styled('p')(
  {
    marginBottom: 10,

    [media('md')]: {
      marginBottom: 20,
    },

    [media('lg')]: {
      marginBottom: 50,
    },
  },
  ({ theme }) => ({
    color: theme.primaryColor,
  }),
);

const JobsHeadline = styled(Headline)(
  {
    margin: '0 0 30px',

    [media('md')]: {
      padding: '0 10%',
      margin: '0 0 60px',
    },

    [media('lg')]: {
      padding: '0 12%',
    },
  },
  ({ theme }) => ({
    color: theme.primaryColor,
  }),
);

const JobsIntro = styled('p')({
  color: '#575757',
  lineHeight: '1.75',
  marginBottom: 40,

  [media('md')]: {
    padding: '0 10%',
  },
});

const JobsContent = styled('div')({
  p: {
    marginBottom: 40,
  },
});

export default class Jobs extends Component {
  render() {
    const {
      data: {
        craft: { jobsEntry, jobsHero },
      },
    } = this.props;
    const { title, jobsIntro, jobsContent, postDate } = jobsEntry;

    return (
      <>
        <Seo
          title={jobsEntry.seoTitle || jobsEntry.title}
          description={jobsEntry.seoDescription}
        />
        {jobsHero?.pageHero.length > 0 && (
          <PageHero
            pageHero={jobsHero.pageHero}
            location={this.props.location}
          />
        )}
        <SectionJobs>
          <Container>
            <JobsHeader>
              {title && (
                <JobsHeadline tag="h2">{parseAsText(title)}</JobsHeadline>
              )}
              {jobsIntro && <JobsIntro>{jobsIntro}</JobsIntro>}
            </JobsHeader>
            {jobsContent && (
              <JobsContent>
                <TextDefault tag="div" className="text">
                  <div
                    dangerouslySetInnerHTML={{ __html: jobsContent.content }}
                  />
                </TextDefault>
              </JobsContent>
            )}
          </Container>
        </SectionJobs>
      </>
    );
  }
}

// dateCreated @date(as: "F j, Y")
export const JobsQuery = graphql`
  query JobsQueryById($id: [Int]!) {
    craft {
      jobsHero: entry(slug: "karriere") {
        ... on Craft_Pages {
          seoTitle
          seoDescription
          pageHero {
            ...PageHeroQuery
          }
        }
      }
      jobsEntry: entry(id: $id) {
        ... on Craft_Jobs {
          seoTitle
          seoDescription
          id
          title
          postDate
          jobsIntro
          jobsContent {
            content
          }
        }
      }
    }
  }
`;
